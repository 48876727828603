'use client';

import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useRouter } from 'next/navigation';

export const ClearButton = () => {
  const { refresh } = useRouter();
  return (
    <button
      type="button"
      className="clear-button"
      onClick={async () => {
        await refreshTags(['userProfile']);
        // await refreshGraphql();
        // refresh();
      }}
    >
      Clear
    </button>
  );
};
