// custom implementation of groupBy because https://github.com/lodash/lodash/issues/5394
export function groupBy(array, getKey) {
    return array.reduce((accumulator, item) => {
        const key = getKey(item);
        if (!accumulator[key]) {
            accumulator[key] = [];
        }
        accumulator[key].push(item);
        return accumulator;
    }, {});
}
