import { getDefaultWeb } from '@edeeone/juan-core/routes/utils/getDefaultWeb';
import { groupBy } from '@edeeone/juan-core/utils/groupBy';
function hasDuplicatePrefixes(webs) {
    const prefixCount = {};
    webs.forEach((web) => {
        const { prefix } = web;
        if (prefixCount[prefix]) {
            prefixCount[prefix] += 1;
        }
        else {
            prefixCount[prefix] = 1;
        }
    });
    return Object.values(prefixCount).some((count) => {
        return count > 1;
    });
}
export const getWebs = () => {
    const envWebs = process.env.JUAN_WEBS;
    const webs = typeof envWebs === 'string' ? JSON.parse(envWebs) : envWebs;
    const websWithoutLocaleSpecific = Object.entries(webs || {}).map(([webId, web]) => {
        return {
            ...web,
            webId,
        };
    });
    function _getHostWebs(host) {
        const groupedWebs = groupBy(websWithoutLocaleSpecific, (web) => {
            return web.domain;
        });
        const hostBag = (host ?? getDefaultWeb().domain).split('://');
        const normalizedHost = hostBag[1] || hostBag[0];
        return groupedWebs[normalizedHost];
    }
    return websWithoutLocaleSpecific.map((webWithoutLocaleSpecific) => {
        const hostWebs = _getHostWebs(webWithoutLocaleSpecific.domain);
        // check if host have uniq prefixes
        if (hasDuplicatePrefixes(hostWebs)) {
            throw new Error(`Host ${webWithoutLocaleSpecific.domain} has duplicate prefixes`);
        }
        const defaultHostWeb = hostWebs[0];
        return {
            ...webWithoutLocaleSpecific,
            defaultLocale: defaultHostWeb.locale,
        };
    });
};
